<template>
	<div>
		<!-- Show process map (if any) -->
		<WizardMap v-if="map" v-model="map" :input="map"></WizardMap>

		<!-- Else, show empty -->
		<NoData v-else heading="app.no_maps_yet" :show_action="false"></NoData>
	</div>
</template>

<script>
import NoData from "@/components/shared/NoData.vue";
import WizardMap from "@/components/shared/tools/wizardMindmap/Index.vue";

export default {
	name: "WizardMapWork",

	data() {
		return {
			map: null,
		};
	},

	components: {
		NoData,
		WizardMap,
	},

	mounted() {
		this.getProcessMap();
	},

	methods: {
		async getProcessMap() {
			try {
				this.$loader.start();

				let wizard_map = await this.$store.dispatch("whiteboard/list", {
					category: this.$defines.SYSTEM_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP,
				});

				if (wizard_map && wizard_map.length) {
					this.map = wizard_map[0];
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/buttonGroup.scss";
</style>
